import { removeGatsbyImagesPost } from "../utils/removeGatsbyImages"
import sanitizeSeo from "../utils/sanitizeSeo"
import { compose } from "../utils/index."
import { ItemTemplateFragment } from "../../../templates/items/data"

var itemNodes = ItemTemplateFragment.replace(
  "fragment ItemTemplateFragment on WPGraphQL_Item",
  "nodes"
).replace("WPGraphQL_", "")

var config = {
  transformer: compose(sanitizeSeo, removeGatsbyImagesPost),
  things: [
    {
      condition: ({ params }) => params?.[process.env.GATSBY_LATEST_PARAM],
      name: process.env.GATSBY_LATEST_PARAM,
      gqlString: ({ pageContext }) => {
        return `
        query {
          item(id: "${pageContext?.item?.id}") {
            id
            slug
            revisions(first: 1) {
              ${itemNodes}
            }
          }
        }
        `
      },
    },
    {
      condition: ({ params }) => params?.[process.env.GATSBY_REVISION_PARAM],
      name: process.env.GATSBY_REVISION_PARAM,
      gqlString: ({ pageContext, params }) => {
        return `
        query  {
          item(id: "${pageContext?.item?.id}") {
            id
            slug
            revisions(where: {id: ${
              params[process.env.GATSBY_REVISION_PARAM]
            }}) {
              ${itemNodes}
            }
          }
        }       
        `
      },
    },
  ],
}

export default config
