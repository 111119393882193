const ItemTemplateFragment = `
    fragment ItemTemplateFragment on WPGraphQL_Item {
        id
        link
        title
        excerpt
        acfItems {
            content
            categories {
                ... on WPGraphQL_ItemCategory {
                    id
                    name
                    slug
                }
            }
        }
        featuredImage {
            altText
            sourceUrl
            imageFile {
                childImageSharp {
                    fluid(quality: 90, cropFocus: CENTER) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
        }
        seo {
            ...SEOFields
        }
  }
`

module.exports.ItemTemplateFragment = ItemTemplateFragment
