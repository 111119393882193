const FluidImageFragment = `
fragment GatsbyImageSharpFluid_withWebp_noBase64 on ImageSharpFluid {
    aspectRatio
    src
    srcSet
    srcWebp
    srcSetWebp
    sizes
}
`
module.exports.FluidImageFragment = FluidImageFragment

const SEOFragment = `
    fragment SEOFields on WPGraphQL_SEO {
        title
        focuskw
        metaDesc
        canonical
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        twitterDescription
        twitterTitle
        breadcrumbs {
            text
            url
        }
        opengraphImage {
            altText
            mediaItemUrl
        }
        twitterImage {
            altText
            mediaItemUrl
        }
    }
`
module.exports.SEOFragment = SEOFragment
