import React from "react"
import styled from "styled-components"

import SEO from "../../../components/shared/SEO"
import Inner from "../../../dsm/layout/Inner"
import Button from "../../../dsm/buttons"
import Layout from "../../../dsm/layout"
import PageHeader from "../../../layouts/PageHeader"
import { CreateLocalLink } from "../../../utils"
import CategoriesNavigation from "../categories/categoriesNavigation"
import getFlexiOptionsLayouts from "../../../options/getFlexiOptionsLayouts"
import getFlexiOptionsBranding from "../../../options/getFlexiOptionsBranding"
import { getColorLuminance } from "../../../utils"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const ItemSingleLayoutA = ({ pageContext }) => {
  const { lightSkin, primaryColor } = getFlexiOptionsBranding()

  const {
    item: { link, title, acfItems, seo },
  } = pageContext

  const {
    websiteSkeleton: { mainBgColor },
  } = getFlexiOptionsLayouts()

  return (
    <Layout>
      <SEO seo={seo} />
      <PageHeader
        title={title}
        crumbs={[
          { pathname: "/", crumbLabel: "Home" },
          {
            pathname: `/${process.env.GATSBY_ITEMS_CATALOGUE_SLUG_PLURAL}/`,
            crumbLabel: process.env.GATSBY_ITEMS_CATALOGUE_SLUG_PLURAL,
          },
          {
            pathname: CreateLocalLink(link).replace(
              "item",
              process.env.GATSBY_ITEMS_CATALOGUE_SLUG_SINGULAR
            ),
            crumbLabel: title,
          },
        ]}
      />
      <Wrapper
        lightSkin={lightSkin}
        primaryColor={primaryColor}
        mainBgColor={mainBgColor}
      >
        <Inner>
          <div className="item-info">
            {/* <h1>{title}</h1> */}
            <div
              dangerouslySetInnerHTML={{ __html: acfItems.content }}
              className="item-single-content"
            />
          </div>
          <div className="sidebar">
            <div className="categories-widget">
              <h4>
                {process.env.GATSBY_ITEMS_CATALOGUE_SLUG_SINGULAR} Categories
              </h4>
              <CategoriesNavigation />
            </div>
          </div>
        </Inner>
        <BackToAll lightSkin={lightSkin} mainBgColor={mainBgColor}>
          <Inner>
            <Button
              to={`/${process.env.GATSBY_ITEMS_CATALOGUE_SLUG_PLURAL}/`}
              label={`Back to all ${process.env.GATSBY_ITEMS_CATALOGUE_SLUG_PLURAL}`}
              type="primary"
            />
          </Inner>
        </BackToAll>
      </Wrapper>
    </Layout>
  )
}
export default ItemSingleLayoutA

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const Wrapper = styled.div`
  background: ${({ theme, lightSkin, mainBgColor }) =>
    mainBgColor
      ? mainBgColor
      : lightSkin
      ? theme.colors.tusk[200]
      : theme.colors.midnight[500]};
  padding-top: 40px;
  .inner {
    display: grid;
    grid-template-columns: 1fr 300px;
    grid-gap: 40px;
  }
  .video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
  }
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .item-single-content {
    padding: 10px 0 40px 0;
    h1,
    h2,
    h3 {
      line-height: 1.1;
    }
    a {
      overflow-wrap: break-word;
      word-break: break-word;
    }
    .alignright {
      width: 40%;
      float: right;
      margin: 0 0 2em 2em;
    }
    .alignleft {
      width: 40%;
      float: left;
      margin: 0 2em 2em 0;
    }
    .aligncenter {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    ul {
      display: flow-root;
    }
    table {
      border: 1px solid
        ${({ theme, lightSkin }) =>
          lightSkin ? theme.colors.tusk[400] : theme.colors.midnight[300]};
      border-radius: 4px;
      border-collapse: collapse;
      border-spacing: 0;
      td {
        border: 1px solid
          ${({ theme, lightSkin }) =>
            lightSkin ? theme.colors.tusk[400] : theme.colors.midnight[300]};
        padding: 10px;
        p {
          margin: 0;
        }
      }
      tr:nth-child(odd) {
        background: #fff;
      }
    }
  }
  .categories-widget {
    position: sticky;
    top: 20px;
    h4 {
      font-weight: 400;
      margin: 0;
      text-transform: capitalize;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    .inner {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    .item-single-content {
      .alignright,
      .alignleft {
        width: 100%;
        float: none;
        margin: 0;
      }
    }
  }
`
const BackToAll = styled.div`
  background: ${({ theme, lightSkin, mainBgColor }) =>
    mainBgColor
      ? getColorLuminance(mainBgColor, 0.2)
      : lightSkin
      ? theme.colors.tusk[100]
      : theme.colors.midnight[400]};
  margin-top: 40px;
  padding: 30px 0;
  a {
    text-transform: uppercase !important;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    .inner {
      justify-items: center;
    }
  }
`
