import React from "react"
import ItemSingleLayoutA from "./single/LayoutA"
import withWPRevisionQuery from "../../components/WPLiveGatsby/withWPRevisionQuery"
import config from "../../components/WPLiveGatsby/config/item-revisions.config"
import useWPRevision from "../../components/WPLiveGatsby/useWPRevision"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const ItemSingle = ({ pageContext, previewQuery }) => {
  // Swap out Layout
  const Layout = ItemSingleLayoutA

  var { revision } = useWPRevision(previewQuery)

  const payload = revision ? { ...pageContext, item: revision } : pageContext

  return <Layout pageContext={payload} />
}
export default withWPRevisionQuery(config)(ItemSingle)
