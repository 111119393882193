import replaceNodes from "./replaceNodes"
import { SEOFragment } from "../../../templates/fragments"
import gql from "graphql-tag"

function sanitizeSeo(ast) {
  var seoAST = gql(SEOFragment)
  var seoSelectionSet = seoAST.definitions[0].selectionSet
  return replaceNodes(["seo"], seoSelectionSet, ast)
}

export default sanitizeSeo
